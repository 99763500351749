<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>Survey - {{ survey.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="
              onSubmit();
              isEditing = false;
            "
            v-bind="attrs"
            :disabled="!valid"
            v-on="on"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isEditing && tab != 3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="isEditing = true"
            v-bind="attrs"
            :disabled="!valid"
            v-on="on"
            :loading="isBusy"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            @click="
              fetchData();
              isEditing = false;
            "
            v-bind="attrs"
            :disabled="!valid"
            v-on="on"
            v-if="!isNew"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Cancel") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="!isEditing && tab == 3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="$refs.answers.generateCSVReport()"
            :disabled="loading || isGeneratingCSVReport"
          >
            <v-icon v-if="!isGeneratingCSVReport">mdi-download</v-icon>
            <v-progress-circular indeterminate color="primary" v-if="isGeneratingCSVReport"></v-progress-circular>
          </v-btn>
        </template>
        <span>Export Report</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>Details</v-tab>
        <v-tab v-if="!isNew">Audience</v-tab>
        <v-tab v-if="!isNew">Questions</v-tab>
        <v-tab v-if="!isNew">Answers</v-tab>
        <v-tab-item eager>
          <ApiError :errors="errors"></ApiError>
          <SurveyDetails
            ref="details"
            :disabled="!isEditing"
            :id="this.$route.params.id"
            @reload="$router.push({ name: 'survey', params: { id: $event } })"
          ></SurveyDetails>
        </v-tab-item>
        <v-tab-item eager>
          <SurveyAudience
            ref="audience"
            :disabled="!isEditing"
            :id="this.$route.params.id"
            :showParticipantGroups="false"
          ></SurveyAudience>
        </v-tab-item>
        <v-tab-item eager>
          <SurveyQuestions ref="questions" :disabled="!isEditing" :id="this.$route.params.id"></SurveyQuestions>
        </v-tab-item>
        <v-tab-item eager>
          <v-card>
            <SurveyAnswers
              ref="answers"
              :id="this.$route.params.id"
              :fileNameStart="survey.name"
              @generatingCSV="isGeneratingCSVReport = true"
              @finishedGeneratingCSV="isGeneratingCSVReport = false"
            ></SurveyAnswers>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.newOptionTextField {
  color: rgba(0, 0, 0, 0) !important;
}
</style>
<script>
import SurveyAnswers from "../../../gapp-components/components/surveys/SurveyAnswers.vue";
import SurveyQuestions from "../../../gapp-components/components/surveys/SurveyQuestions.vue";
import SurveyAudience from "../../../gapp-components/components/surveys/SurveyAudience.vue";
import SurveyDetails from "../../../gapp-components/components/surveys/SurveyDetails.vue";
import ApiError from "../../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";
export default {
  components: { ApiError, SurveyDetails, SurveyAudience, SurveyQuestions, SurveyAnswers },
  name: "Survey",
  metaInfo: {
    title: "Survey"
  },
  data() {
    return {
      form: {
        details: {
          name: ""
        },
        audience: {},
        questions: []
      },
      isNew: true,
      isEditing: false,
      loading: false,
      valid: true,
      isBusy: false,
      errors: {},
      page: 1,
      tab: 0,
      survey: { name: "" },
      state: {},
      isGeneratingCSVReport: false
    };
  },
  mounted() {
    this.isNew = this.$route.params.id == 0 ? true : false;

    if (!this.isNew) {
      this.fetchData();
    } else {
      this.isEditing = true;
    }
    this.breadcrumb();
  },
  computed: {
    ...mapGetters(["breadcrumbs", "selectedClient", "selectedProgram"])
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      this.errors = {};
      this.$set(this, "errors", {});
      if (this.isNew) {
        this.$refs.details
          .onSubmit()
          .then(() => {
            this.isNew = false;
            this.isBusy = false;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.isBusy = false;
            this.isEditing = true;
          });
      } else {
        let saveFunctions = [];
        let saveDetails = () => this.$refs.details.onSubmit();
        let saveAudience = () => this.$refs.audience.onSubmit();
        let saveQuestions = () => this.$refs.questions.onSubmit();
        saveFunctions.push(saveDetails, saveAudience, saveQuestions);

        let promises = [];
        promises = saveFunctions.map(func => func());

        Promise.all(promises)
          .then(() => {
            this.isEditing = false;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.isEditing = true;
            this.isBusy = false;
          })
          .finally(() => {
            this.isBusy = false;
            this.fetchData();
          });
      }
    },

    fetchData() {
      this.loading = true;
      this.isBusy = true;
      this.$api
        .get("/api/surveys/" + this.$route.params.id)
        .then(({ data }) => {
          this.$set(this, "survey", data);
        })
        .then(() => {
          let promises = [];
          promises.push(
            this.$refs.details.fetchData(),
            this.$refs.audience.fetchData(),
            this.$refs.questions.fetchData(),
            this.$refs.answers.fetchData()
          );
          return Promise.all(promises);
        })
        .then(() => {
          this.breadcrumb();
          this.isBusy = false;
          this.loading = false;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
          this.isBusy = false;
          this.loading = false;
        });
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Surveys"),
            to: { name: "surveys" },
            exact: true
          },
          { text: "New Survey" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Surveys"),
            to: { name: "surveys" },
            exact: true
          },
          { text: this.survey.name }
        ]);
      }
    }
  }
};
</script>
